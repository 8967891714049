<template>
<main>
	<page-title :pageTitle="this.$options.name" :title="title" :content="content" :img="img" />
    <p class="time-zone-msg text-center">All of our workshops are listed in Pacific Time. Reach out to us if you don't see a time that works for your schedule - we're happy to accommodate!</p>
    <div id="fareharborApp" class="container"></div>  
</main>
</template>

<script>

    import PageTitle from '@/components/PageTitle.vue';
    import heroImg from '@/assets/hero_bookings.jpg';
    import postscribe from 'postscribe';

    export default {
        name: 'Schedule a Workshop',
        components: {
            pageTitle: PageTitle
        },
        data() {
            return {
                img: heroImg,
                title: 'An incredible experience awaits!',
                content: '<p>Please use the calendar to book the date of your next Code With Her workshop.</p><p><strong>Currently, all of our workshops are virtual.</strong></p><em>Note: Our workshops require a minimum of 4 participants. If the minimum is not met 48 hours prior to the scheduled workshop, it will be cancelled with a full refund.</em>'
            };
        },
        mounted: function () {
            postscribe('#fareharborApp', `<script src="https://fareharbor.com/embeds/script/items/codewithher/?full-items=yes&fallback=simple&flow=408948"><\/script>`);
        },
        metaInfo() {
            const desc = this.content.replace(/<\/?[^>]+(>|$)/g, "");
			return {
				title: 'Code with Her | Schedule A Workshop',
				meta: [
					{ name: 'description', content: desc}
				],
                link: [
                    { rel: 'canonical', href: 'https://codewithher.org/schedule-a-workshop' }
                ]
			}
		}
    }

</script>

<style lang="scss">

.time-zone-msg,
#fareharborApp {
    padding: $unit-5;
}

.time-zone-msg {
    background: $warning-color;
}

</style>