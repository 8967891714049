<template>
    <section id="badges" class="container columns cwh-container col-gapless ai-c jc-c">
        <div class="content column">
            <div class="content-header">
                <h2 class="text-left program-title">{{ title }}</h2>
            </div>
            <div class="cards badge-cards content-body flex flex-fw ai-s jc-sb col-12">
                <div class="card badge accordion column flex ai-start col-12 col-md-6" v-for="(badge, index) in badges" :key="badge.title" @click="accordionToggle(index)" :class="{ 'accordion-active': isActive.includes(index) }">
                    <div class="card-header badge-header accordion-header col-12 flex ai-c jc-sb">
                        <h3 class="card-title">{{ badge.title }}</h3>
                        <i class="fas fa-plus accordion-icon"></i>
                    </div>
                    <div class="card-body accordion-body">
                        <div class="card-subtitle flex jc-sb">
                            <div class="badge-age flex ai-fs"><span class="badge-icon"><i class="fas fa-users"></i></span><span> {{ badge.age }}</span></div>
                            <div class="badge-duration flex ai-fs"><span class="badge-icon"><i class="fas fa-clock"></i></span><span> Duration: {{ badge.duration }}hrs</span></div>
                        </div>
                        <p v-html="badge.desc"></p>
                        <div class="badge-cta flex flex-fw jc-sb ai-c">
                            <a target="_blank" :href="link.link" class="badge-link" v-for="(link, index) in badge.links"  :key="link.linkName">
                                <span class="underline">{{ link.linkName }} <i class="fas fa-arrow-right"></i></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
	data() {
		return {
            title: 'Badges',
			badges: [
                {
                    title: 'Coding for Good',
                    age: 'All Levels',
                    duration: 1.5,
                    desc: 'Learn how to use technology as a tool to make the world a better place! Explore apps, identify an issue in your community, and prototype an app as a solution to that issue. Each age level has a 3 badge series: Coding Basics, Digital Game Design, & App Development.<p><strong>This workshop is a two-part series.</strong></p><p>Badge 1 is covered in the series 1. Badges 2 and 3 are covered in the second series.</p>',
                    links: [
                        {
                            linkName: 'Book Badge Series 1',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/243142/?full-items=yes&flow=408948'
                        },
                        {
                            linkName: 'Book Badge Series 2 & 3',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/267569/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Coding Together',
                    age: 'All Levels & Adults',
                    duration: 1.5,
                    desc: 'We believe that it takes a village to empower our future women tech leaders! You and your Girl Scout will be able to participate in a hands-on workshop, coding together, using Scratch. <p><strong>This workshop is a two-part series.</strong></p><p>Badge 1 is covered in the first series. Badges 2 and 3 are covered in the second series.</p>',
                    links: [
                        {
                            linkName: 'Book Badge Workshop',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/236545/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Cybersecurity',
                    age: 'All Levels',
                    duration: 1.5,
                    desc: 'Learn online safety and privacy principles, how the internet works, and spotting & investigating cybercrime. Each age level has a 3 badge series: Basics, Safeguards, & Investigators. <p><strong>This workshop is a two-part series.</strong></p><p>Badge 1 is covered in the first series. Badges 2 and 3 are covered in the second series.</p>',
                    links: [
                        {
                            linkName: 'Book Badge Series 1',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/236532/?full-items=yes&flow=408948'
                        },
                        {
                            linkName: 'Book Badge Series 2 & 3',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/256278/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Digital Leadership',
                    age: 'All Levels',
                    duration: 1.5,
                    desc: 'Technology is a tool used to bring people together and distribute information. A robust understanding of this tool can aid girls in empowering themselves and others to make the world a better place. This girl-led workshop encourages participants to explore topics they’re passionate about with a new lense: a focus on creating social change through community & technology. Activities satisfy badge requirements with an added bonus– participants will also learn basic coding principles along the way! This 1.5 hour virtual workshop is interactive, fun, and appropriate for all program age levels!',
                    links: [
                        {
                            linkName: 'Book Badge Workshop',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/338217/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Math in Nature',
                    age: 'Daisy, Brownie, Junior',
                    duration: 1.5,
                    desc: 'What do climate change & endangered animals have in common? Environmentalism & computation! Girl Scouts learn by doing in this fun 1.5 hour interactive virtual workshop. Activities satisfy badge requirements with an added bonus – participants will integrate technology and nature to learn about math! The new Math in Nature badges build on one another as Girl Scouts earn the Shapes in Nature, Numbers in Nature, and Design in Nature badges. <p><strong>This workshop is a two-part series.</strong></p><p>Badge 1 is covered in the first series. Badges 2 and 3 are covered in the second series.</p>',
                    links: [
                        {
                            linkName: 'Book Badge Series 1',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/338215/?full-items=yes&flow=408948'
                        },
                        {
                            linkName: 'Book Badge Series 2 & 3',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/338216/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Netiquette',
                    age: 'Cadette',
                    duration: 1.5,
                    desc: 'Learn how to make positive choices in the online world by earning this badge. Girls will learn to stay in touch and share ideas safely through social media, emailing and blogging.',
                    links: [
                        {
                            linkName: 'Book Badge Workshop',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/236537/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Tech Entrepreneurship',
                    age: 'Juniors, Cadettes, Seniors, Ambassadors',
                    duration: 2,
                    desc: 'We’ve put a tech spin on the Entrepreneurship badges! Put your innovation cap on and learn how to build a successful business startup around a technology idea.<p>Juniors: Business Jumpstart</p><p>Cadettes: Business Creator</p><p>Seniors: Business Startup</p><p>Ambassadors: Entrepreneur Accelerator</p></ul>',
                    links: [
                        {
                            linkName: 'Book Badge Workshop',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/254566/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Think Like a Programmer Journey',
                    age: 'All Levels',
                    duration: 1.5,
                    desc: 'Start your computer science journey with women in the field! Girls will connect with computer science, & uncover the skills it takes to think like a programmer! <em>Take Action project not included</em><p><strong>This workshop is a two-part series.</strong></p><p>Badge 1 is covered in the first series. Badges 2 and 3 are covered in the second series.</p>',
                    links: [
                        {
                            linkName: 'Book Part 1',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/236523/?full-items=yes&flow=408948'
                        },
                        {
                            linkName: 'Book Part 2',
                            link: 'https://fareharbor.com/embeds/book/codewithher/items/270861/?full-items=yes&flow=408948'
                        }
                    ]
                },
                {
                    title: 'Silver & Gold Award App Creation',
                    age: 'Cadettes, Seniors & Ambassadors',
                    duration: 'Contact for availability',
                    desc: 'Change the world through technology by designing and coding your own app through MIT App Inventor. To create a sustainable change, inform people using your coding skills. Please <a class="link-secondary underline" href="https://goo.gl/forms/AcKq6j5QdEY8L9JR2" target="_blank">contact us</a> for more information.'
                }
            ],
            isActive: []
		};
	},
    methods: {
        accordionToggle: function(index) {

            if (this.isActive.includes(index)) {
                this.isActive = this.isActive.filter(i => i !== index);
                return;
            }

            this.isActive.push(index);
        }
    },
    name: 'BadgeCards'
};

</script>

<style lang="scss" scoped>

// GS BADGE CARDS

.badge {
	
	color: $dark-color;

	.card-header {
		@media (min-width: $size-md) {
			border-bottom: 1px solid $dark-color;
		}
	}

	.card-title {
		display: block;
		font-size: 1.5rem;
		margin: 0 0 5px;
		
		@media (max-width: $size-max-sm) {
			max-width: 80%;
            hyphens: auto;
		}
	}

	.fa-plus {

		&:before {
			font-size: 1.5rem;
		}		
	}

	.card-subtitle {
		font-size: 0.875rem;
		padding: 10px 0;
    }
    
    .badge-age {
        margin-bottom: 0.5rem;

        @media (min-width: $size-md) {
            margin-bottom: 0;
        }
    }

    .badge-icon {
        padding-right: 0.5rem;
    }

	.card-body {

		p {
			margin: 0;

			@media (min-width: $size-md) {
				margin-top: $unit-5;
            }
        }
    }

    .badge-cta {
        margin-top: 1rem;
    }

    .badge-link {
        color: #000;
        cursor: pointer;
        margin-bottom: 1rem;

        .fa-arrow-right {
            margin-left: 0.5rem;
            position: relative;
            right: 0;
            transition: all 0.3s;
        }

        &:hover {

            .fa-arrow-right {
                right: -5px;
            }
        }
    }
}

</style>