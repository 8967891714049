import { render, staticRenderFns } from "./NonprofitDisclaimer.vue?vue&type=template&id=2566aa3e&scoped=true&"
import script from "./NonprofitDisclaimer.vue?vue&type=script&lang=js&"
export * from "./NonprofitDisclaimer.vue?vue&type=script&lang=js&"
import style0 from "./NonprofitDisclaimer.vue?vue&type=style&index=0&id=2566aa3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2566aa3e",
  null
  
)

export default component.exports