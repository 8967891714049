<template>
	<section id="staff" class="container columns cwh-container col-gapless ai-c jc-c">
		<div class="content column">
            <div class="cards team-cards content-body flex flex-fw ai-s jc-c col-12">
                <div class="card team-card popup column col-12 col-md-3 col-xs-6" v-for="(employee, index) in employees" :key="employee.name">
                    <a class="card-header col-12" @click="modalToggle(index)">
                        <div class="card-image">
                            <img :src="require(`@/assets/staff/${employee.img}`)" :alt="employee.name + ':' + employee.title" class="img-responsive">
                        </div>
                        <h3 class="card-title">{{ employee.name }}</h3>
                        <div class="card-subtitle flex jc-sb">
                            <h4 class="team-title">{{ employee.title }}</h4>
                        </div>
                    </a>
					<transition name="fade" appear>
						<div class="overlay" v-if="isActive.includes(index)" @click="close()"></div>
					</transition>
					<transition name="slide" appear>
						<div class="card-body flex ai-start jc-sb flex-column" v-if="isActive.includes(index)">
							<div class="popup-close text-right col-12"  @click="close()">
								<span class="fas fa-times"></span>
							</div>
							<div class="popup-content card-content" v-html="employee.desc"></div>
						</div>
					</transition>
                </div>
            </div>
		</div>
	</section>
</template>

<script>

export default {
    name: 'TeamCards',
	data() {
		return {
			employees: [
                {
                    name: 'Kylie Taitano',
                    title: 'Co-Founder and Instructor',
                    img: 'kylie.jpg',
                    desc: '<p>Kylie has nearly 10 years of experience organizing and advocating for the advancement of women and education in tech. She discovered her passion for these issues in her first year as a Computer Science major at UCSD. Kylie was selected as the Secretary for the founding board of UCSD\'s undergraduate Women in Computing group, serving as President in her senior year. During her time with WIC, she worked with her board to create several programs and events to recruit, retain, and support women in tech, including raising scholarship funds to send over a dozen students to the 2013 Grace Hopper Celebration of Women in Computing.</p><p>Kylie currently works as a Software Engineer at Intuit; outside of her daily work, she spent three years as a member of the Intuit Tech Women of Intuit core team focusing on K-12 outreach. In this role, she partnered with Girl Scouts San Diego to help Girl Scout troops across San Diego discover the limitless opportunities in tech through various programs like the You Can Be in Tech day conference and the 12-week Technovation Challenge program. Kylie also participates as a current board member of the UCSD Computer Science and Education Alumni Advisory Board. She has been recognized for her advocacy work, helping Intuit to be recognized as one of 2017 Community Partner of the Year, and receiving Athena San Diego\'s inaugural Pinnacle Award for Rising Star in 2019.</p>'
                },
                {
                    name: 'Manisha Aswani',
                    title: 'Co-Founder and Instructor',
                    img: 'manisha.jpg',
                    desc: '<p>Manisha currently is a Technical Program Manager at Facebook in the Privacy Infrastructure organization. She has been in the tech industry for 20 years and has held managerial and individual contributor positions at various times in her career. With a background in Computer Science, she has experience in Quality Engineering, Datacenter Migration, Backend Infrastructure and Technical Program Management.</p><p>She is passionate about teaching and believes that Code With Her provides an ecosystem where kids can learn to code via integrated learning. Her goal is for Code With Her programs to continuously evolve, address the complexities of engineering and make programming fun.</p><p>Manisha is a mom to two kids. She is involved in her kids\' school activities in addition to soccer and swimming.</p>'
                },
                {
                    name: 'Michaela Mitchell',
                    title: 'Co-Founder and Instructor',
                    img: 'michaela.jpg',
                    desc: '<p>Michaela is an Account Marketing Manager at the San Diego Blood Bank. After graduating with a bachelor\'s degree in Business Administration from Washington State University , she served as a Community Economic Development volunteer in Peace Corps Costa Rica. After, she worked for Girl Scouts San Diego where she learned the true meaning of  STEMinism, and  has since been an advocate for girls of all backgrounds to have opportunities in the technology field. Michaela has served as the co-chair for the Women in STEM committee in the San Diego STEM Ecosystem and was a Mentor for the Technovation Challenge. </p>'
                },
                {
                    name: 'Maile Lucks',
                    title: 'Frontend Software Engineer and Instructor',
                    img: 'maile_v2.jpg',
                    desc: '<p>Maile is a frontend web developer with over 8 years of experience in the industry. Maile graduated from Towson State University with a degree in Television Production. She later decided to become a web developer and taught herself how to code. Maile is a strong advocate for self-learning and empowering students to take initiative and acquire new skills using nothing more than Google and a curious mind. When she isn\'t coding, Maile can be found hiking, traveling, or trying new food.</p>'
                },
                {
                    name: 'Kaija Marshall',
                    title: 'Growth Engineer',
                    img: 'kaija.jpg',
                    desc: '<p>Kaija earned her Master\'s degree in Social Work from San Diego State University in 2015. A lifelong Girl Scout, her educational emphasis organically shifted away from counseling and into group work and community organizing in graduate school. She has enjoyed working as an evaluation specialist, camp counselor & outreach program leader for Girl Scouts San Diego, as a program manager for a postpartum doula agency, as a small business owner hosting wellness workshops, as a doula serving parents of newborns during the 4th trimester and as a yoga instructor! Her passion is helping others feel empowered in their thoughts, feelings and drives through strategic action and quiet reflection. She is working with Code With Her to create evaluation tools that help participants conceptualize their experience and instructors to continually improve as workshop facilitators!</p>'
                },
                {
                    name: 'Sharon Vien',
                    title: 'Instructor',
                    img: 'sharon.jpg',
                    desc: '<p>Sharon has a mission of empowering and providing opportunities for underrepresented communities to explore careers in the tech industry. As a Statistical Data Science major at UC Davis, she understands the challenges of breaking into the tech field. By combining her 6 years of experience working with kids and her enthusiasm for CWH\'s mission, she hopes to make an impact.</p>'
                },
                {
                    name: 'Jenn Truong',
                    title: 'Instructor',
                    img: 'jen.jpg',
                    desc: '<p>Jennifer has 7 years of experience working with children and education. After graduating from UCSD with a Bachelor\'s degree in Psychology, she has been working as a teacher in after school programs. She switched gears from after school teaching to teaching conversational English to elementary students in South Korea. Having thrown herself into the unknown, she realized that a lot of adventure awaits those with a curious mind. While working in rural schools in South Korea, it really cemented her belief that everyone should have the opportunity to embrace their curiosities and explore different interests. Once she returned to America, she worked as a Site Director at an after school program.</p> <p>With her background in education, she hopes to support and strengthen CWH\'s mission. She hopes to inspire the participants to embrace the unknown and thrive from the adventures. Be it tech or any other field, Jennifer hopes that her students will wonder, explore, and challenge themselves so that they can find what inspires them to progress towards a brighter future.</p>'
                },
                {
                    name: 'Lina Battikha',
                    title: 'Instructor',
                    img: 'lina.jpg',
                    desc: '<p>Lina has worked with kids in many different settings, from teaching them programming languages to aiding them throughout summer camps, discovering her passion to share knowledge that will advance their growth and confidence. She started out as a volunteer with CWH, getting an opportunity to truly understand CWH\'s mission as she helped out in various workshops. She hopes to continue to make an impact, reaching more students through this nonprofit. She will be a student at UC San Diego, majoring in Data Science, starting Fall 2021.</p>'
                }
            ],
			isActive: []
		};
	},
	methods: {
        modalToggle: function(index) {

            if (this.isActive.includes(index)) {
                this.isActive = this.isActive.filter(i => i !== index);
                return;
            }

            this.isActive.push(index);

			document.querySelector('body').classList.toggle('kill-scroll');
        },
		close: function() {
			this.isActive = [];
			document.querySelector('body').classList.toggle('kill-scroll');
		}
    }
};

</script>

<style lang="scss" scoped>

// OUR TEAM
.popup-close {
	cursor: pointer;
}

.team-cards {
	padding-bottom: 0 !important;
}

.team-card {

	&.card.column {
		margin: 0 2rem 2rem;
		padding: 0.5rem;

		@media (min-width: $size-md) {

			&:first-of-type {
				margin-left: 5%;
			}
		
		}

		@media (min-width: $size-lg) {

			flex: 1 1 24%;
			margin-top: $unit-10;
			margin-bottom: 0;
			max-width: 24%;
	
		}
	}

	.card-header {
		flex: 0 0 auto;
	}

	.card-title {
		border-bottom: 1px solid #303742;
		display: inline-block;
		margin: 10px 0;
		padding: 0 0 5px;
	}

	.card-subtitle {

		.team-title {
			font-size: 0.9rem;
			margin-top: 0;
		}
	}

	.overlay {
		background: rgba( 0,0,0,0.6 );
		position: fixed;
		top: 0;
		transform: translate(0%, 0%);
		width: 100%;
		z-index: 98;
	}
}

.card-body {
	background: $gray-color-light;
	height: auto;
	left: 50%;
	max-width: 500px;
	overflow: scroll;
	padding: $unit-6;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	z-index: 99;

	@media (min-width: $size-md) {

		height: auto;
	
	}
}


</style>
