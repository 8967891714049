<template>
<main>
	<EventListings />
</main>
</template>

<script>

	import PageTitle from '@/components/PageTitle.vue';
	import EventListings from '@/components/EventListings.vue';

	export default {
		name: 'Events',
		components: {
			pageTitle: PageTitle,
			EventListings
		},
		metaInfo() {
			const desc = this.content.replace(/<\/?[^>]+(>|$)/g, "");
			return {
				title: 'Code with Her | Events',
				meta: [
					{ name: 'description', content: desc}
				],			
                link: [
                    { rel: 'canonical', href: 'https://codewithher.org/events' }
                ]
			}
		}
	}

</script>