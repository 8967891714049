<template>
    <section id="schools" class="container programs columns cwh-container col-gapless ai-c jc-c">
        <div class="program content column col-12" v-for="program in programs" :key="program.title">
            <div class="content-header">
                <h2 class="text-left program-title">{{ program.title }}</h2>
            </div>
            <div class="cards content-body flex flex-fw ai-s jc-sb col-12">
                <div class="card program-point accordion column flex ai-start col-12" v-for="(point, index) in program.points" :key="point.title" @click="accordionToggle(index)" :class="{ 'accordion-active': isActive.includes(index) }">
                    <div class="card-header accordion-header col-12 flex ai-c jc-sb">
                        <h3 class="card-title">{{ point.title }}</h3>
                        <i class="fas fa-plus accordion-icon"></i>
                    </div>
                    <div class="card-body accordion-body">
                        <p v-html="point.desc"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

export default {
    name: 'SchoolCards',
	data() {
		return {
			programs: [
                {
                    title: 'After School Programs',
                    points: [
                        {
                            title: 'Coding',
                            desc: 'Topics include hard skills such as Scratch, MIT App Inventor and soft skills Computational Thinking'
                        },
                        {
                            title: 'Technovation Challenge',
                            desc: 'Change the world through technology by designing and coding your own app through MIT App Inventor. To create a sustainable change, inform people using your coding skills. <a target="_blank" href="https://technovationchallenge.org/">Learn more here.</a>'
                        }
                    ]
                },
                {
                    title: 'In School Programs',
                    points: [
                        {
                            title: 'Coding',
                            desc: 'Topics include hard skills such as Scratch, MIT App Inventor and soft skills Computational Thinking'
                        },
                        {
                            title: 'Integrated Learning',
                            desc: 'Customized programs that are designed around school curriculum. Students will apply coding skills to learn about and solve real-world problems.'
                        }
                    ]
                }
            ],
            isActive: []
		};
	},
    methods: {
        accordionToggle: function(index) {

            if (this.isActive.includes(index)) {
                this.isActive = this.isActive.filter(i => i !== index);
                return;
            }

            this.isActive.push(index);
        }
    }
};

</script>

<style lang="scss">

.program {

    &.content {

        &:not(:first-of-type) {
            padding-top: 0;
        }

            &:first-of-type {
            padding-bottom: 1rem;
        }
    }
}

.program-title {
    font-size: $unit-8;
    margin-top: 0;
    width: 100%;
}

.program-point {

    a {
        text-decoration: underline;
    }

	.card-header {
        @media (min-width: $size-md) {
			border-bottom: 1px solid $dark-color;
		}
        
		.card-title {
			font-size: 1.5rem;
			letter-spacing: 1px;
			margin: 0 0 5px;	
		}
	}
}

</style>