<template>
	<header id="cwh-header" class="cwh-header container col-gapless" :class="{ 'nav-open' : navOpen }">
		<div id="opacityLayer" :class="{ 'nav-open' : navOpen }"  @click="navOpen = !navOpen"></div>
		<div class="column flex flex-fw jc-sb ai-c jc-start-lg">
			<div class="logo-figure d-flex ai-c flex-shrink">
				<router-link @click="navOpen = !navOpen" to="/">
					<img src="../assets/Logo.jpg" alt="Code With Her" class="logo">
				</router-link>
			</div>
			<nav class="flex-grow" :class="{ 'nav-open' : navOpen }">
				<div id="nav-container" class="d-flex ai-start ai-c-lg jc-sb-lg">
					<div class="mobile-menu hide-lg col-12 text-right"  @click="navOpen = !navOpen">
						<span class="fas fa-times"></span>
					</div>
					<div class="nav-item" v-on="windowWidth < 1200 ? { click: () => navOpen = !navOpen }  : { click: ($event) => $event.preventDefault() }">
						<router-link to="/programs"><span class="underline">Programs</span></router-link>
					</div>
					<div class="nav-item" v-on="windowWidth < 1200 ? { click: () => navOpen = !navOpen }  : { click: ($event) => $event.preventDefault() }">
						<router-link to="/events"><span class="underline">Events</span></router-link>
					</div>
					<div class="nav-item" v-on="windowWidth < 1200 ? { click: () => navOpen = !navOpen }  : { click: ($event) => $event.preventDefault() }" >
						<router-link to="/our-team"><span class="underline">Our Team</span></router-link>
					</div>
					<div class="nav-item" v-on="windowWidth < 1200 ? { click: () => navOpen = !navOpen }  : { click: ($event) => $event.preventDefault() }" >
						<router-link to="/schedule-a-workshop"><span class="underline">Schedule a Workshop</span></router-link>
					</div>
					<div class="nav-item" v-on="windowWidth < 1200 ? { click: () => navOpen = !navOpen }  : {}">
						<a :href="'https://docs.google.com/forms/d/e/1FAIpQLScUb9FNnh1q_Nd2QcixgdEhUId6S7lS_KvK79Lty7mUU2CCbg/viewform'" target="_blank"><span class="underline">Get Involved</span></a>
					</div>
					<div class="nav-item" v-on="windowWidth < 1200 ? { click: () => navOpen = !navOpen }  : { click: ($event) => $event.preventDefault() }">
						<router-link to="/donate"><span id="donate_button" class="button primary"><span id="donate_underline" class=underline>Make a Donation</span></span></router-link>
					</div>
				</div>
			</nav>
			<div ref="mobileClose" class="mobile-menu mobile-menu-main hide-lg" @click="navOpen = !navOpen">
				<span class="fas fa-bars"></span>
			</div>
		</div>
	</header>
	<!-- End Header -->
</template>

<script>

	export default {
		name: 'Header',
		data() {
			return {
				navOpen: false,
				windowWidth: window.innerWidth
			}
		},
		mounted() {
			window.onresize = () => {
				this.windowWidth = window.innerWidth
			}
		}
	}

</script>

<style lang="scss" scoped>

// HEADER
.cwh-header {

	background-color: #fff;
	transition: all .2s;
	z-index: 9;

	.fa-icon:before {
		color: $dark-color;
		font-size: 1.5rem;
	}

	.mobile-menu {
		font-size: $unit-8;
		position: relative;
	}

	.mobile-menu-main {
		right: 20px;
	}

	a {
		color: $dark-color;
		display: block;
		font-weight: bold;
		text-transform: uppercase;

		&.button {
			color: #fff;
		}
	}

	.logo-figure {
		padding: 10px 0 10px;
	}

	.logo {
		max-width: 95px;
	}

	&.sticky {
		border-bottom: 1px solid $dark-color;
		height: 0;
		opacity: 0;
		position: relative;
		visibility: hidden;
		top: -90px;
		transform: scaleY(0);
		transition: opacity .2s;

		&.visible {
			height: auto;
			opacity: 1;
			position: fixed;
			top: 0;
			transform: scaleY(1);
			visibility: visible;
		}
	}

	//NAV

	@media (max-width: $size-max-md) {

		nav {
			background: #fff;
			display: block;
			height: 100%;
			overflow-y: auto;
			position: fixed;
			top: 0;
			left: 0;
			padding: 0.25em 0.75em 1em 0.75em;
			-moz-transform: translateX(-315px);
			-webkit-transform: translateX(-315px);
			-ms-transform: translateX(-315px);
			transform: translateX(-315px);
			transition: 0.5s transform;
			width: 315px;
			z-index: 999;
			
			a {
				cursor: pointer;
				padding: $unit-4 0;
			}

			.nav-item {

				&:hover {
					cursor: pointer;
				}
			}
		}

		nav.nav-open {
			-moz-transform: translateX(0);
			-webkit-transform: translateX(0);
			-ms-transform: translateX(0);
			transform: translateX(0);
		}

		#nav-container {
			flex-direction: column;
		}

		.nav-highlight {
			display: none;
		}
	}

	@media (min-width: $size-lg) {

		#nav-container {
			padding-left: 3rem;
		}
		
		.nav-item {
			cursor: pointer;
			margin: 0 $unit-5;
			padding: 0;

			&:last-of-type {
				margin-right: 0;
			}
		}

		.nav-highlight {
			display: flex;
		}
	}

	@media (min-width: $size-xl) {

		.nav-item {
			margin: 0 $unit-10;
		}
	}
}

</style>