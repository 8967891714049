import Vue from 'vue'
import CWH from './CWH.vue'
import router from './router'
import Meta from "vue-meta"
import './sass/libs_custom/_vars.scss'
import './sass/core.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false
Vue.use(Meta);

new Vue({
	data: {
		navOpen: false
	},
	render: h => h(CWH),
	router,

}).$mount('#cwh');
  