<template>
<main>
	<page-title :pageTitle="this.$options.name" :title="title" :content="content" :img="img" />
    <BadgeCards />
    <CTA />
    <SchoolCards />
    <CTA />
</main>
</template>

<script>

    import PageTitle from '@/components/PageTitle.vue';
    import CTA from '@/components/CTA.vue';
    import SchoolCards from '@/components/SchoolCards.vue';
    import BadgeCards from '@/components/BadgeCards.vue'
    import heroImg from '@/assets/hero_programs.jpg';

    export default {
        name: 'Programs',
        components: {
            pageTitle: PageTitle,
            CTA,
            SchoolCards,
            BadgeCards
        },
        data() {
            return {
                img: heroImg,
                title: 'Learn more about our programs!',
                metaDesc: 'Code With Her is proud to offer a variety of programs for Girls Scouts and for all students.',
                content: '<p>Code With Her is proud to offer a variety of workshops. Click below to learn more:</p><p class="programs-ctas flex jc-sb ai-c"><a href="#badges" class="button transparent"><span class="underline">Girl Scout Badges </span></a><a href="#schools" class="button transparent"><span class="underline">Schools</span></a></p>'
            };
        },
        metaInfo() {
			return {
				title: 'Code with Her | Programs',
				meta: [
					{ name: 'description', content: this.metaDesc },
                ],
                link: [
                    { rel: 'canonical', href: 'https://codewithher.org/programs' }
                ]
			}
		}
    }

</script>

<style lang="scss">

.programs-ctas {

    flex-direction: column;
    @media (min-width: $size-md) {
        flex-direction: row;
    }

    .button {
        width: 100%;

        &:first-of-type {
            margin-bottom: 1rem;

            @media (min-width: $size-md) {
                margin-bottom: 0;
                margin-right: 1rem;
            }
        }
    }
}

</style>