<template>

    <section id="philosophy" class="container columns cwh-container col-gapless ai-c jc-c">
        <div class="content column">
            <div class="content-header">
                <h2 class="text-center">Our Philosophy</h2>
                <p class="text-center">Together with our community, we are on the the pursuit of the following principles:
                </p>
            </div>
            <div class="cards principle-cards content-body flex flex-fw ai-s jc-sb col-12">
                <div class="card principle bg-secondary accordion column flex flex-fw ai-start col-12 col-md-6" v-for="(point, index) in philosophy" :key="point.title" @click="accordionToggle(index)" :class="{ 'accordion-active': isActive.includes(index) }">
                    <div class="card-header principle-header accordion-header col-12 flex ai-c jc-sb">
                        <h3 class="card-title">{{ point.title }}</h3>
                        <i class="fas fa-plus accordion-icon"></i>
                    </div>
                    <div class="card-body accordion-body">
                        <p v-html="point.desc"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

export default {
    name: 'Philosophy',
	data() {
		return {
			philosophy: [
                {
                    title: 'Leadership',
                    desc: 'Develop creativity, courage, and confidence in all participants to be the leaders of tomorrow.'
                },
                {
                    title: 'Skill-Building',
                    desc: 'Teach students the fundamental skills to succeed in future coding courses and pathways.'
                },
                {
                    title: 'Impact',
                    desc: 'Increase the number of young women interested in pursuing a career in technology.'
                },
                {
                    title: 'Community',
                    desc: 'Create a supportive environment of women in technology, where we are empowered to speak our minds, celebrate our successes, and support each other through our journey.'
                },
                {
                    title: 'Inclusiveness',
                    desc: 'Promote equity, diversity and equality in technology opportunities and education.'
                },
                {
                    title: 'Possibility',
                    desc: 'Expand participants perspective of the endless opportunities in the technology field.'
                }
            ],
            isActive: []
		};
	},
    methods: {
        accordionToggle: function(index) {

            if (this.isActive.includes(index)) {
                this.isActive = this.isActive.filter(i => i !== index);
                return;
            }

            this.isActive.push(index);
        }
    }
};

</script>

<style lang="scss">

// PRINCIPLE CARDS
.principle {

	.card-header {

        @media (min-width: $size-md) {
			border-bottom: 1px solid $dark-color;
		}

		.card-title {
			font-size: 1.5rem;
			letter-spacing: 1px;
			margin: 0 0 5px;	
		}
	}
}

</style>