<template>
    <article class="callout">
        <div class="copy">
            <span v-html="icon"></span>
            <span class="text-bold" v-html="action"></span>
            <p v-html="desc"></p>
        </div>
    </article>
</template>
<script>

export default {
    name: 'Callout',
	data() {
		return {
            action: 'Double your impact! ',
            icon: '⚡️',
            desc: 'Check with your employer to explore how you can leverage matching gifts from your workplace',
		};
	}
};

</script>

<style lang="scss" scoped>

.callout {
    margin: 1rem auto 2rem;
    padding: 0 2rem;
    
    .copy {
        background-color: $storm;
        border-radius: 8px;
        color: #fff;
        padding: 1rem;

        p {
            display: inline;
            margin: 0;
        }
    }
}

</style>