<template>

	<section class="container cwh-container cta-container flex ai-c jc-c">
        <router-link class="bookings-cta button primary" to="/schedule-a-workshop">
            <span class="underline">Schedule your workshop</span>
        </router-link>
    </section>

</template>

<script>

    export default {
        name: 'CTA'
    }

</script>

<style lang="scss" scoped>

    .cta-container {
        padding: 2rem 2rem 3rem;

        &:last-of-type {
            padding-bottom: 9rem;
        }

        .button {
            font-size: 1rem;
            max-width: 25rem;
            min-width: 16rem;
            padding: 1.5rem;
            width: 100%;
        }
    }

</style>