<template>
<main>
	<page-title :pageTitle="this.$options.name.replace(/([a-z])([A-Z])/g, '$1 $2')" :title="title" :content="content" :img="img"/>
	<TeamCards />
</main>
</template>

<script>

    import PageTitle from '@/components/PageTitle.vue'
    import TeamCards from '@/components/TeamCards.vue'
	import heroImg from '@/assets/hero_ourteam.jpg'

    export default {
        name: 'OurTeam',
		data() {
			return {
				img: heroImg,
				title: 'A community of leaders and innovators.',
				content: '<p>Meet our wonderful staff that include a diverse group of industry professionals.</p>'
			};
		},
        components: {
            pageTitle: PageTitle,
            TeamCards
		},
		metaInfo() {
			const desc = this.content.replace(/<\/?[^>]+(>|$)/g, "");
			return {
				title: 'Code with Her | Our Team',
				meta: [
					{ name: 'description', content: desc}
				],
                link: [
                    { rel: 'canonical', href: 'https://codewithher.org/our-team' }
                ]
			}
		}
    }

</script>
