<template>
<div>
	<Header />
	<PromoBanner />
	<router-view/>
	<Footer />
</div>
</template>

<script>

	import Header from '@/components/Header.vue';
	import PromoBanner from '@/components/PromoBanner.vue';
	import Footer from '@/components/Footer.vue';

	export default {
		components: {
			Header,
			PromoBanner,
			Footer
		},
		metaInfo: {
			title: 'Code With Her',
			titleTemplate: '%s | Empowering Students With Technology'
		}
	};

</script>

<style lang="scss">

@import './sass/core';

</style>
