<template>
<main class="container columns cwh-container col-gapless ai-c jc-c">
    <section class="content column">
        <page-title-text :pageTitle="pageTitle" :content="content"/> 
        <DedicatedCards />
        <DonationTiers />
        <Callout />
        <div id="keelaScript" class="container"></div>  
        <div id="keelaForm" class="container"></div> 
        <NonprofitDisclaimer />
    </section>
</main>
</template>

<script>

    import Callout from '@/components/Callout.vue';
    import DedicatedCards from '@/components/DedicatedCards.vue';
    import DonationTiers from '@/components/DonationTiers.vue';
    import NonprofitDisclaimer from '@/components/NonprofitDisclaimer';
    import PageTitleText from '@/components/PageTitleText.vue';
    import postscribe from 'postscribe';

    export default {
        name: 'DonateToCodeWithHer',
        components: {
            Callout,
            DedicatedCards,
            DonationTiers,
            NonprofitDisclaimer,
            pageTitleText: PageTitleText
        },
        data() {
            return {
                content: '<p>Code With Her provides real-world coding experiences to empower youth to be the next leaders in technology through integrated learning and community connections. Your support will directly fund new partnerships with schools and community based organizations, as well as maintain our Opportunity Fund, all which will provide no cost tech programs.</p>',
                pageTitle: 'Make a difference with Code With Her'
            };
        },
        mounted: function () {
            postscribe('#keelaForm', `<iframe id="keela_GtksxuZrvj2vqygww" style="width: 100%; min-height: 500px;" frameborder="0" src="https://donate-usa.keela.co/embed/season-of-giving"><\/iframe>`);
            postscribe('#keelaScript', `<script type="text/javascript" src="https://donate-usa.keela.co/js/iframeResizer.min.js" onload="iFrameResize({checkOrigin: false, heightCalculationMethod: 'taggedElement'}, '#keela_GtksxuZrvj2vqygww')" async><\/script>`);
        },
        metaInfo() {
            const desc = this.content.replace(/<\/?[^>]+(>|$)/g, "");
			return {
				title: 'Code with Her | Donate to Code With Her',
				meta: [
					{ name: 'description', content: desc}
				],
                link: [
                    { rel: 'canonical', href: 'https://codewithher.org/donate' }
                ]
			}
		}
    }

</script>

<style lang="scss">

    #keelaForm {
        margin-top: 4rem;
        text-align: center;
    }

    .header {
        display: block;
       font-size: 1rem; 
       font-weight: 700;
       margin-bottom: 1rem;
    }

</style>