<template>
    <section id="donationTiers" class="content">
        <h2 class="header">Impact of your donation</h2>
        <div class="tiers-wrapper flex ai-c jc-sb">
            <ul class="card column tiers flex flex-fw ai-s jc-sb col-12">
                <li class="tier col-12" v-for="tier in tiers" :key="tier.id">
                    <p class="tier-details">
                        <span class="amount" v-html="tier.amount"></span>
                        <span class="impact-copy" v-html="tier.copy"></span>
                    </p>
                </li>
            </ul>
            <div class="img-column column">
                <img :src="require(`@/assets/${donateImg}`)" alt="Donate to Code with Her" class="tier-img img-responsive">
            </div>
        </div>
        <div class="thankyou flex ai-fs col-12">
            <p v-html="thankYou"></p>
        </div>
    </section>
</template>
<script>

export default {
    name: 'DonationTiers',
	data() {
		return {
            donateImg: "donate-img.jpg",
            thankYou: 'Thank you for empowering youth to use technology as a tool to improve their lives and make the world a better place!',
            tiers: [
                {
                    id: 1,
                    amount: '$5',
                    copy: 'One step closer to equity in tech-ed!',
                },
                {
                    id: 2,
                    amount: '$20',
                    copy: 'Provide a student access to a self-guided coding project.',
                },
                {
                    id: 3,
                    amount: '$50',
                    copy: 'Provide a student access to a workshop led by a mentor in tech.',
                },
                {
                    id: 4,
                    amount: '$100',
                    copy: 'Support an enrichment activity for a staff or intern to learn and grow.',
                },
                {
                    id: 5,
                    amount: '$250',
                    copy: 'Fund one community based workshop for a group or troop led by a mentor in tech.',
                },
                {
                    id: 4,
                    amount: '$500',
                    copy: 'Fund a “Coding in the Community” series for a local organization or group.',
                },
                {
                    id: 4,
                    amount: '$1000',
                    copy: 'Fund an integrated learning project in a classroom.',
                },
            ]
		};
	}
};

</script>

<style lang="scss" scoped>

#donationTiers {
    padding: 1rem 2rem;
}

.tiers-wrapper {
    flex-direction: column;

    @media (min-width: $size-md) {
        flex-direction: row;
    }
}

.tiers {
    box-shadow: none;
}

.tier {
    padding: 1rem;
}

.amount {
    display: block;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.tier-details {
    margin: 0;
}

.img-column {
    padding: 0;
    
    @media (min-width: $size-md) {
        padding-left: 0.5rem;
        padding-right: 0;
    }
}

.tier-img {
    margin-top: 1rem;
    padding: 0;

    @media (min-width: $size-md) {
        margin-top: 0;
    }
}

.thankyou {
    font-style: italic;
}

</style>