<template>
	<section class="partnership-icons column flex ai-c col-12 flex-column">
		<h3>Our Partners</h3>
		<div class="partner-icons-inner flex jc-sa flex-fw flex-nw-lg col-12">
			<div class="partner-icon flex jc-c ai-c" v-for="(partner, index) in partners" :key="partner.name">
				<a :id="partner.id" target="_blank" :href="partner.link" class="flex flex-column jc-c ai-c">
					<img class="partner-logo" :src="require(`@/assets/${partner.logo_file}`)" :alt="partner.name + ' logo'"/>
					<span class="partner-name">{{ partner.name }}</span>
				</a>
			</div>
		</div>
	</section>
</template>

<script>

    export default {
        name: 'Partnerships',
        data() {
            return {
				partners: [
					{
						id: 'girl-scouts',
						name: 'Girl Scouts',
						logo_file: 'gs_logo.svg',
						link: 'https://www.girlscouts.org/'
					},
					{
						id: 'd39',
						name: 'Design39Campus',
						logo_file: 'logo_d39.png',
						link: 'https://design39campus.com/'
					},
					{
						id: 'mitzy',
						name: 'Mitzy Makes It',
						logo_file: 'logo_mitzy.png',
						link: 'https://mitzymakesit.com/'
					}
					// {
					// 	id: 'odihi',
					// 	name: 'Odihi',
					// 	logo_file: 'logo_odihi.webp',
					// 	link: 'https://www.odihi.com/'
					// }
				]
            };
        }
    }

</script>

<style lang="scss" scoped>

   .partnership-icons {
        background-color: #515760;
		color: #FFF;
		padding: $unit-10;

		h3 {
			color: #FFF;
			font-size: 1.5rem;
			letter-spacing: 0.15rem;
			margin-top: 0;
		}

		@media (min-width: $size-md) {
			padding: 2rem 2rem 1rem;
		}
	}

	.partner-icon {
		flex-basis: 100%;
		margin-bottom: 2rem;
		padding: 0.5rem;

		img {
			max-height: 100px;
			width: auto;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
		
		// #odihi {
		// 	margin-bottom: 0;
		// 	margin-top: 1rem;
		// }

		@media (min-width: $size-md) {
			flex-basis: 33%;
		}
	}

	.partner-name {
		font-size: 1.5rem;
		margin-top: $unit-5;
	}

</style>