<template>
<div class="content-header page-title">
	<h1 class="text-left header"> {{ pageTitle }} </h1>
	<div class="text-left white copy" v-html="content"></div>
</div>
</template>

<script>

	export default  {
		props: {
			content: {
				type: String,
				required: true
			},
			pageTitle: {
				type: String,
				required: true
			}
		}
	}


</script>

<style lang="scss" scoped>

.header {
	color: $secondary-color;
	font-size: 2rem;
	margin-top: 0;
}

.copy {
	line-height: 1.5;
	
	&:first-line {
		font-weight: 700;
	}
}

</style>