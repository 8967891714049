<template>
    <section id="dedicatedCards">
        <h2 class="header">We are dedicated to</h2>
        <div class="cards dedicated-cards flex ai-c jc-sb col-12">
            <div class="card flex flex-column ai-c col-12" v-for="dedication in dedications" :key="dedication.id">
                <span class="icon" v-html="dedication.icon"></span>
                <h3 class="subheader" v-html="dedication.desc"></h3>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: 'DedicatedCards',
	data() {
		return {
			dedications: [
                {
                    id: 1,
                    desc: 'Closing the gender and diversity gap in technology',
                    icon: '👩🏽‍💻 ',
                },
                {
                    id: 2,
                    desc: 'Ensuring technology is accessible to all students',
                    icon: '🖥',
                },
                {
                    id: 3,
                    desc: 'Providing programs to all students regardless of background or geographic location',
                    icon: '🗺',
                },
            ],
		};
	}
};

</script>

<style lang="scss" scoped>

#dedicatedCards {
    padding: 0 2rem;

    .subheader {
        font-size: 0.875rem;
        font-weight: 400;
    }
}

.cards {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;

    @media (min-width: $size-sm) {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;         
    }
}

.card {
    background: #F1F1F1;
    border-radius: 4px;
    box-shadow: none;
    height: 100%;
    margin: 0;
    padding: 1rem;

    .icon {
        display: block;
        font-size: 4rem;
    }

    p {
       font-size: $unit-4;
       font-weight: normal;
       margin: 0;
       text-align: left; 
    }
}

</style>