import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Workshops from '../views/Workshops.vue'
import Events from '../views/Events.vue'
import OurTeam from '../views/OurTeam.vue'
import ScheduleAWorkshop from '../views/ScheduleAWorkshop.vue'
import Programs from '../views/Programs.vue'
import Donate from '../views/Donate.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Code With Her',
			metaTags: [
			  {
				name: 'description',
				content: 'Code With Her provides real-world coding experiences to empower your child to be the next leader in technology'
			  }
			]
		}
	},
	{
		path: '/schools',
		redirect: '/programs',
		name: 'Schools Redirect',
		component: Programs,
	},
	{
		path: '/workshops',
		redirect: '/programs',
		name: 'Workshops',
		component: Workshops
	},
	{
		path: '/events',
		name: 'Events',
		component: Events
	},
	{
		path: '/events.html',
		redirect: '/events',
		name: 'Events Mispelling',
		component: Events
	},
	{
		path: '/our-team',
		name: 'Our Team',
		component: OurTeam
	},
	{
		path: '/schedule-a-workshop',
		name: 'Schedule A Workshop',
		component: ScheduleAWorkshop
	},
	{
		path: '/ schedule-a-workshop',
		redirect: '/schedule-a-workshop',
		name: 'Schedule A Workshop Mispealling',
		component: ScheduleAWorkshop
	},
	{
		path: '/bookings',
		redirect: '/schedule-a-workshop',
		name: 'Redirect from bookings',
		component: ScheduleAWorkshop
	},
	{
		path: '/programs',
		name: 'Programs',
		component: Programs
	},
	{
		path: '/donate',
		name: 'Donate to Code With Her',
		component: Donate
	}
]
  
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router