<template>
    <section id="nonprofitDisclaimer" class="content">
        <p class="disclaimer-copy" v-for="disclaimer in disclaimers" :key="disclaimer.id" v-html="disclaimer.copy">
        </p>
    </section>
</template>
<script>

export default {
    name: 'NonprofitDisclaimer',
	data() {
		return {
            disclaimers: [
                {
                    id: 1,
                    copy: 'For more information on how to support Code With Her, contact us at <a class="link-primary" href="mailto:info@codewithher.org">info@codewithher.org</a>.',
                },
                {
                    id: 2,
                    copy: 'Sign up for our <a class="link-primary" target="_blank" href="https://mailchi.mp/3030d598d5fe/codewithher_signup">Bits and Bytes E-newsletter</a> to stay up to date with Code With Hers programs, partnerships and impact.',
                },
                {
                    id: 3,
                    copy: 'If you prefer to pay by check, please contact us at <a class="link-primary" href="mailto:info@codewithher.org">info@codewithher.org</a>.',
                },
                {
                    id: 4,
                    copy: 'Code With Her is a nonprofit 501(c)(3) organization (EIN/tax ID number 82-3622791). Your gift is tax-deductible as provided by the Internal Revenue Service’s standards and regulations.',
                },
            ]
		};
	}
};

</script>

<style lang="scss" scoped>

.callout {
    margin: 1rem auto 2rem;
    padding: 0 2rem;
    
    .copy {
        background-color: $storm;
        border-radius: 8px;
        color: #fff;
        padding: 1rem;
    }
}

</style>